import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import DocSidebar from '../components/DocsComponents/sidebar';
import '../assets/css/style.css';

const DocsPage = (props) => {

  console.log(props);
  
  return (
  <Layout>
    <SEO title="Documentations for Emailkick" meta={"Documentations needed to setup emailkick to your application. Find all the relevent information your need to send your first email."} />
    <div className="blog-post-container pt-12">
        <div className="blog-post py-2 flex">
          {/* <DocSidebar pageId={'get-started'} /> */}
          <div className="flex-1">
            <div className="max-w-4xl mx-auto py-20 px-6">
              <h1 className="text-3xl font-medium">Documentation</h1>
              <h4 className="text-base text-gray-600 mt-2 ">Explore our guides and examples to integrate Emailkick.</h4>

              <div className="mt-12">
                <div className="text-base mb-4 text-gray-600" >How to</div>
                <div className="grid grid-cols-1">
                  <Link className="w-full flex-1 bg-white rounded-md border border-gray-200 shadow-sm px-4 duration-75 py-4 hover:shadow hover:border-gray-300 flex" to='/docs/how/link-emailkick-with-aws-ses/'>
                    <div className="w-16 flex items-center">
                      <img className="w-10 h-10" src={'/icons8-add-link.svg'} />
                    </div>
                    <div className="flex-1 w-fulll">
                      <h2 className="text-lg font-medium">Link your Amazon SES with Emailkick</h2>
                      <p className="mt-1 text-sm text-gray-600">Simple steps to integrate your Amazon Simple Email Service to Emailkick.</p>
                      <div className="flex  mt-2">
                        <span className="text-xs text-gray-500 bg-gray-200 rounded-md px-2 py-1">Takes only 2 minutes</span>
                        <span className="text-xs text-gray-500 bg-gray-200 rounded-md px-2 py-1 ml-2">Required</span>
                      </div>
                    </div>
                  </Link>

                  <Link className="w-full mt-6 bg-white flex-1 rounded-md border border-gray-200 duration-75 shadow-sm px-4 py-4 hover:shadow hover:border-gray-300 flex" to='/docs/how/setup-delivery-statistics-on-emailkick/'>
                    <div className="w-16 flex items-center">
                      <img className="w-10 h-10" src={'/icons8-data-transfer.svg'} />
                    </div>
                    <div className="flex-1 w-fulll">
                      <h2 className="text-lg font-medium">Setup realtime delivery statistics</h2>
                      <p className="mt-1 text-sm text-gray-600">Emailkick uses SES integrated SNS to gather realtime email statistics.</p>
                      <div className="flex  mt-2">
                        <span className="text-xs text-gray-500 bg-gray-200 rounded-md px-2 py-1">Takes 4 minutes</span>
                      </div>

                    </div>
                  </Link>

                  <Link className="w-full mt-6 flex-1 bg-white rounded-md border border-gray-200 duration-75 shadow-sm px-4 py-4 hover:shadow hover:border-gray-300 flex" 
                    to='/docs/how/sending-email-using-api/'>
                    <div className="w-16 flex items-center">
                      <img className="w-10 h-10" src={'/icons8-send-email.svg'} />
                    </div>
                    <div className="flex-1 w-fulll">
                      <h2 className="text-lg font-medium">Sending email using API</h2>
                      <p className="mt-1 text-sm text-gray-600">Sending email using from your application is very easy. Follow this guide to send your email.</p>
                      <div className="flex  mt-2">
                        <span className="text-xs text-gray-500 bg-gray-200 rounded-md px-2 py-1">Takes 2 minutes</span>
                      </div>

                    </div>
                  </Link>


                </div>
              </div>


            </div>
          </div>
        </div>
    </div>

  </Layout>
)}

export const pageQuery  = graphql`
{
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          date
          title
          slug
          section
          sectionId
          pageCode
          nextLink
          next
          featImg
        }
      }
    }
  }
}
`

export default DocsPage
