import { Link } from 'gatsby';
import React from 'react'

import { StaticQuery, graphql } from "gatsby"


const SendingAPIQuery = graphql`query SendingAPIQuery {
    allMarkdownRemark(filter: {frontmatter: {sectionId: {eq: "4"}}}) {
    edges {
        node {
        frontmatter {
            date
            title
            smallTitle
            slug
            section
            sectionId
            pageCode
            nextLink
            next
            featImg
        }
        }
    }
    }
}
  `


  const SendingAPIListing = (props) => {
      return (
        <StaticQuery
        query={SendingAPIQuery}
        render={data => {
            console.log(data.allMarkdownRemark.edges);
            return (
                <>
                <span className="text-gray-600 text-sm">Sending API</span>
                <ul className="ml-1 mt-2">
                    {data.allMarkdownRemark.edges.map(page => (
                        <li className="py-1">
                            <Link 
                                className={`cursor-pointer text-sm ${props.pageId == page.node.frontmatter.pageCode ? `text-gray-800 font-semibold` :`text-gray-600 hover:text-gray-800 font-normal opacity-80`}`} 
                                to={page.node.frontmatter.slug}>{page.node.frontmatter.smallTitle}</Link>
                        </li>
                    ))}
                </ul>
                </>
        )}}
      />
      )
  }

 const DocSidebar = (props) => {
    return (
        <div className="w-1/4 border-r border-gray-100 bg-white h-full fixed -left-64 md:left-0">
            <div className="px-4 py-2" >
                <ul className="m-0 p-0 text-base">
                    <li className="py-2 text-medium">
                        <Link 
                            className={`cursor-pointer text-sm ${props.pageId == 'get-started' ? `text-gray-700 font-semibold` :`text-gray-500 hover:text-gray-800 font-normal opacity-80`}`} 
                            to="/docs/">Getting Started</Link>
                    </li>
                  
                    <li className="py-3 text-medium">
                        <span className="text-gray-600 text-sm">Emailkick Integration</span>
                        <ul className="ml-1 mt-2">
                            <li className="py-1">
                                <Link 
                                    className={`cursor-pointer text-sm ${props.pageId == 'how/link-emailkick-with-aws-ses' ? `text-gray-800 font-semibold` :`text-gray-600 hover:text-gray-800 font-normal opacity-80`}`} 
                                    to="/docs/how/link-emailkick-with-aws-ses/">Link Emailkick with SES</Link>
                            </li>
                            <li className="py-1">
                                <Link 
                                    className={`cursor-pointer text-sm ${props.pageId == 'how/setup-delivery-statistics-on-emailkick' ? `text-gray-800 font-semibold` :`text-gray-600 hover:text-gray-800 font-normal opacity-80`}`} 
                                    to="/docs/how/setup-delivery-statistics-on-emailkick/">Activate email statistics</Link>
                            </li>
                            <li className="py-1">
                                <a 
                                    className={`cursor-pointer text-sm ${props.pageId == 'how/sending-email-using-api' ? `text-gray-800 font-semibold` :`text-gray-500 hover:text-gray-800 font-normal opacity-80`}`} 
                                    href="/docs/how/sending-email-using-api/">Sending emails using API</a>
                            </li>
                        </ul>
                    </li>

                    <li className="py-3 text-medium">
                        <SendingAPIListing {...props} />
                    </li>



                    <li className="py-3 text-medium">
                        <span className="text-gray-400 text-sm">Setting up AWS</span>
                        <div className="text-gray-500 text-xs mt-2">Coming soon</div>
                        {/* <ul className="ml-2 mt-2">
                            <li className="py-1"><a className="cursor-pointer text-gray-500 hover:text-gray-800 font-normal text-sm" href="#">Add domain to SES</a></li>
                            <li className="py-1"><a className="cursor-pointer text-gray-500 hover:text-gray-800 font-normal text-sm" href="#">Increase Limit and Allow sending</a></li>
                            <li className="py-1"><a className="cursor-pointer text-gray-500 hover:text-gray-800 font-normal text-sm" href="#">Get API Key</a></li>
                        </ul> */}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DocSidebar;